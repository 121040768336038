
























import {Component, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";
import LocationTable from "@/components/LocationTable.vue";
import {User} from "@/api";

const Auth = namespace("Auth");
@Component({
  components: {LocationTable}
})
export default class Location extends Vue {
  @Auth.State("user")
  private currentUser!: User;


  mounted() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }
  }

}
